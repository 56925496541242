@media only screen and (max-width: 600px) {
    .TopProductImage{
         height: 400px !important;
         padding-bottom: 20px;
    }

    }

.ProductsContainer{
    background-color: none;
    display: flex;
    margin: auto;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
}

.productList{
    margin: 0px 20px 0px 20px;
}

.pset{
    margin-bottom: 20px;
}

.TopProductImage{
    height: 500px;
}

.Ptitle{
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
    padding-bottom: 5px;
    color: #222647;
}

.pdescription{
    margin: auto;
    color: #2D2D2D;
    font-family: 'Poppins', sans-serif;
    font-weight: 300;
    max-width: 700px;
    padding-bottom: 5px;
    text-align: justify;
}

.pcta{
    text-decoration: none;
    color: #4C51BF;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
}

.MainContainer{
    display: flex;
    flex-direction: column;
    margin-bottom: 90px;
    margin-top: 90px;

}

.ProductCTA{
    background-color: none;
    text-align: center;
    margin: auto;
    max-width: 150px;
    border: 2px solid #222647;
    border-radius: 34px;
    padding: 10px 30px 12px 30px; 
    cursor: pointer;
    margin-top: 20px;
}

.ProductA{
    text-decoration: none;
    color: #222647;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
}

.aBtn{
    color: #222647;
    text-decoration: none;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    text-align: center;
    margin: auto;
    max-width: 150px;
    border: 2px solid #222647;
    border-radius: 34px;
    padding: 10px 30px 12px 30px; 
    cursor: pointer;
    margin-top: 20px;
}

.aBtn:hover{
    background-color: #222647;
    color: #ffffff;
    text-decoration: none;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
}