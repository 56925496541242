@media only screen and (max-width: 900px) {
    /* .menu {
      display: none;
    } */

    .logo{
        width: 200px !important;
       }

    .divider{
    margin: 0px 15px 1px 15px !important;
    padding-bottom: 0.005rem;
    }

    .menuElements{
    margin: 0px 20px 15px 25px !important;
    display: flex;
    padding-top: 30px;
    justify-content: space-between;
    flex-wrap: wrap;
    }

  } 

.container{
    background-color: #222647;
    position: fixed;
    width: 100%;
    z-index: 1;
}

.menuElements{
    margin: 0px 150px 15px 150px;
    display: flex;
    padding-top: 30px;
    justify-content: space-between;
    flex-wrap: wrap;
}

.logo{
 width: 200px;
}

.divider{
    background-color: #ffffff;
    margin: 0px 150px 1px 150px;
    padding-bottom: 0.005rem;
}

.menu{
    display: flex;
    justify-content: left;
    flex-wrap: wrap;
}

.menuli{
    padding-left: 30px;
    list-style: none;
    display: flex;
    color: white;
}


.menulabel {
    font-size: 26px;
    line-height: 70px;
    display: none;
    float: right;
    color: #ffffff;
}
#toggle {
    display: none;
}


@media only screen and (max-width: 500px) {
    .menulabel {
        display: flex;
        cursor: pointer;
    }

    .menu {
        margin-top: 30px;
        width: 100%;
        display: none;
    }

    
    .menuli{
        justify-content: center;
        padding-left: 0px;
        list-style: none;
        display: flex;
        color: white;
        padding-bottom: 30px;
    }

    
       
    #toggle:checked + .menu {
        display: flex;
        flex-direction: column;
        height: 20vh; 
          }

    
}
