
.description{
    background-color: #222647;

}

.descriptionHeading{
    text-align: center;
    margin: auto;
    color: white;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    font-size: 45px;
    max-width: 550px;
    line-height: 50px;
    padding: 60px 0px 15px 0px;
    margin-top: 60px;
}

.descriptionParagraph{
    text-align: center;
    margin: auto;
    color: white;
    font-family: 'Poppins', sans-serif;
    font-weight: 300;
    font-size: 18px;
    max-width: 670px;
    padding: 0px 10px 20px 10px;
}

.CTA{
    background-color: #222647;
    color: white;
    text-align: center;
    margin: auto;
    max-width: 150px;
    border: 2px solid #FFFFFF;
    border-radius: 34px;
    padding: 10px 20px 12px 20px; 
    cursor: pointer;
}

.heroImage{
    width: 100%;
    background-color: #222647;
    margin-bottom: 0px;
    padding-bottom: 0px;
    padding-top: 20px;

}

.heroa{
    text-decoration: none;
    color: white;
}

