@media only screen and (max-width: 1080px) {
    
    .hisImage{
     display: none;    }
}


.pRtmsdescription{
    margin-top: 50px;
    padding-bottom: 30px;
    padding-top: 60px;
}

.contactRtmsParagraph{
    text-align: center;
    margin: auto;
    color: #2D2D2D;
    font-family: 'Poppins', sans-serif;
    font-weight: 300;
    font-size: 18px;
    max-width: 930px;
    padding: 0px 20px 20px 20px;
}

.Hiscontainer{
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
}

.hisImage{
    margin: auto;
    width: 900px;
    margin-bottom: 40px;
}

.HISdescription{
    text-align: left;
    margin: auto;
    color: #2D2D2D;
    font-family: 'Poppins', sans-serif;
    font-weight: 300;
    font-size: 18px;
    max-width: 930px;
    padding: 0px 20px 20px 20px;

}

