.pRtmsdescription{
    margin-top: 50px;
    padding-bottom: 30px;
    padding-top: 60px;
}

.contactRtmsParagraph{
    text-align: center;
    margin: auto;
    color: #2D2D2D;
    font-family: 'Poppins', sans-serif;
    font-weight: 300;
    font-size: 18px;
    max-width: 630px;
    padding: 0px 20px 20px 20px;
}

.contactHcontainer{
    margin-top: 40px;
}