.Rtmsdescription{
    background-color: #ECF1ED;
    margin-top: 50px;
    padding-bottom: 60px;
    padding-top: 60px;
}

.subHeading{
   text-align: center;
    margin: auto;
    color: #222647;
    font-family: 'Poppins', sans-serif;
    font-weight: 300;
    font-size: 25px;
    max-width: 300px;
    line-height: 50px;
    padding: 0px 0px 0px 0px;  
}

.RtmsHeading{
    text-align: center;
    margin: auto;
    color: #222647;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    font-size: 35px;
    max-width: 700px;
    line-height: 50px;
    padding: 0px 0px 15px 0px;  
}

.RtmsParagraph{
    text-align: center;
    margin: auto;
    color: #2D2D2D;
    font-family: 'Poppins', sans-serif;
    font-weight: 300;
    font-size: 18px;
    max-width: 1030px;
    padding: 0px 20px 20px 20px;
}

.RtmsCTA{
    background-color: none;
    text-align: center;
    margin: auto;
    max-width: 150px;
    border: 2px solid #222647;
    border-radius: 34px;
    padding: 10px 1px 12px 1px; 
    cursor: pointer;
    color: #222647;
}

.heroImage{
    max-width: 100%;
    background-color: #222647;
    margin-bottom: 0px;
    padding-bottom: 0px;
    padding-top: 20px;

}
.Rtmsa{
    color: #222647;
    text-decoration: none;
}

.aboutHcontainer{
    margin-top: 40px;
    padding-top: 0px;
}
