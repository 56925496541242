@media only screen and (max-width: 1080px) {
    .ContactContainer{
        display: flex;
        flex-wrap: wrap !important;
    }

    .FormContainer{
        max-width: 280px;
    }

    .contactImage{
     display: none;    }
}

.ContactContainer{
    display: flex;
    flex-direction: row;
    max-width: 1180px;
    background: #ECF1ED;
    margin: auto;
    margin-bottom: 60px;
}
 
.contactImage{
    height: 600px;
}

.FormContainer{
    padding: 50px;
    width: 600px;
}

.InputTitle{
    margin: auto;
    color: #222647;
    font-family: 'Poppins', sans-serif;
    font-weight: 900;
    font-size: 30px;
    margin-bottom: 25px;
}


.inputbox{
    position: relative;
    height: 50px;
    border-bottom: 1px solid #222647;
    margin-bottom: 30px;
    font-size: 14px;
    font-family: 'Poppins', sans-serif;
    color: #222647;
}

.inputbox input{
    height: 50%;
    width: 100%;
    padding-top: 25px;
    border: none;
    background-color: transparent;
}

.inputbox label{
    position: absolute;
    bottom: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    pointer-events: none;
}

input:focus {
    outline: none !important;
} 

textarea{
    background-color: none;
}

.noticeText{
    margin-top: 0px;
    font-size: 15px;
    font-family: 'Poppins', sans-serif;
    color: #222647;

}

.InputSubmitBtn{
    background-color: #222647;
    color: white;
    border: none;
    border-radius: 90px;
    padding: 15px 20px 15px 20px ;
    margin-top: 20px;
    font-size: 15px;
}

.InputSubmitBtn:hover{
    background-color: white;
    color: #222647;
    border: 2px solid #222647;
}