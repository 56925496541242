@media only screen and (max-width: 900px) {
    .FooterContainer{
        padding: 40px 20px 10px 40px !important;
    }

    .FooterCopyright{
        text-align: center;
        padding-top: 10px;
    }

    .Fmenu{
        padding-left: 0px;
        list-style-type: none;
    }

    li{
        padding-left: 0px;
        list-style-type: none;

    }

    .Fdivider{
        margin-top: 20px !important;
    }

}

.FooterContainer{
    background-color: #222647;
    padding: 80px 160px 10px 160px;
    color: white;
    font-weight: 200;
    flex-direction: column;
    align-items: center;
}

.Fintro{
    max-width: 300px;
    padding: 10px 0px 0px 0px;
}

.Fdivider{
    background-color: #ffffff;
    padding-bottom: 0.005rem;
    margin-top: 70px;
}

.FooterCopyright{
    color: #ffffff;
    display: flex;
    flex-direction:column ;
    align-items: center;
    padding-top: 10px;
}

.FooterCollection{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.Fmenu{
    display: flex;
    flex-direction: column;  
    list-style-type: none;

}

.FmenuTitle{
    font-weight: 600;
}

a{
    text-decoration: none;
    color: #ffffff;
}

a:hover {
    font-weight: 500;
}

