@media only screen and (max-width: 800px) {
.featuresContainer{
    display: flex;
    flex-wrap: wrap;
}

}

.description2{
    background-color: #ffffff;

}

.descriptionHeading2{
    text-align: center;
    margin: auto;
    color: #222647;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    font-size: 35px;
    max-width: 750px;
    line-height: 50px;
    padding: 60px 0px 15px 0px;
    
}

.descriptionParagraph2{
    text-align: center;
    margin: auto;
    color: #2D2D2D;
    font-family: 'Poppins', sans-serif;
    font-weight: 300;
    font-size: 18px;
    max-width: 850px;
    padding-bottom: 20px;
}

.featuresContainer{
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    margin: auto;
    max-width: 1300px;
}

.Feature{
    display: flex;
    flex-direction: column;
    align-items:center ;
    margin: auto;
    margin:20px;
}

.FeatureTitle{
    font-family: 'Poppins', sans-serif;
    font-size: 20px;
    font-weight: 500;
}

.featureDescription{
    text-align: center;
    margin: auto;
    color: #2D2D2D;
    font-family: 'Poppins', sans-serif;
    font-weight: 300;
    font-size: 16px;
    max-width: 450px;
    padding-bottom: 20px;
}

